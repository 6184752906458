<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->

        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">BANK</h3>

          <div class="form-col-select ml-2">
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block"
            />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Find Bank"
            />
          </div>
          <b-button
            class="px-3 mobile-w100"
            variant="primary"
            @click="addBank"
            v-if="ability.can('create', 'bank')"
            :to="{ name: 'banks-create' }"
          >
            <span class="text-nowrap"
              ><feather-icon size="14" icon="PlusIcon" /> Bank</span
            >
          </b-button>
        </div>
      </div>
      
      <b-table
        ref="refBankListTable"
        class="position-relative"
        :items="fetchBanks"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
         <template #cell(name)="data">
            <b-link class="text-decoration-underline" v-if="ability.can('update','bank')"
            :to="{ name: 'banks-edit', params: { id: data.item.id } }"
              >{{ data.item.name }}</b-link
            >
            <div v-else>{{data.item.name}}</div>
          </template>
        <!-- Column: Bank Code -->
        <template #cell(bank_code)="data">
          <b-link v-if="ability.can('update','bank')"
            class="action-trigger-btn"
            :to="{ name: 'banks-detail', params: { id: data.item.id } }"
            >{{ data.item.bank_code }}</b-link
          >
            <div v-else>{{data.item.bank_code}}</div>
        </template>
        <template #cell(updated_at)="data">
            <div class="d-flex">
              {{ getCorrectDateTime(data.item.updated_at) }}
            </div>
           
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <b-dropdown
            class="action-trigger-btn"
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

             <b-dropdown-item v-if="ability.can('read', 'bank')"
              :to="{ name: 'banks-detail', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
             </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('update', 'banks') || true"
              :to="{ name: 'banks-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('delete', 'bank')"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBanks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import bankStoreModule from "../bankStoreModule";
import useBanksList from "./useBanksList";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the bank.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-bank/deleteBank", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addBank() {
      this.isAddNewBankSidebarActive = true;
    },
  },
  setup() {
    const APP_CLIENT_STORE_MODULE_NAME = "app-bank";
    const isAddNewBankSidebarActive = ref(false);

    if (!store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
      store.registerModule(APP_CLIENT_STORE_MODULE_NAME, bankStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_CLIENT_STORE_MODULE_NAME))
        store.unregisterModule(APP_CLIENT_STORE_MODULE_NAME);
    });

    const {
      fetchBanks,
      tableColumns,
      perPage,
      currentPage,
      totalBanks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refBankListTable,
      ability,
    } = useBanksList();

    return {
      isAddNewBankSidebarActive,
      fetchBanks,
      tableColumns,
      perPage,
      currentPage,
      totalBanks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refBankListTable,
      ability,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>
  
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>
  
